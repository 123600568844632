import { GET_USER } from '../types';

export const fetchUser = () => {
    return function (dispatch) {
        fetch('/api/users')
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: GET_USER,
                    payload: data,
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
