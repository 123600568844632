import { GeneralPage } from './pages/GeneralPage/GeneralPage';
import MainPage from './pages/MainPage/MainPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';

import TestCreation from './pages/TestCreation/TestCreation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCountingTypes, fetchStaticDirections, fetchStaticTags, fetchTests } from './Redux/reducers/mainReducer';
import { fetchUser } from './Redux/actions/userActions';
import './App.css';

function App() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        if(user?._id){
            dispatch(fetchTests());
            dispatch(fetchCountingTypes());
            dispatch(fetchStaticTags());
            dispatch(fetchStaticDirections());
        }
    }, [user?._id])

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<GeneralPage />} />
                    <Route path="/tests" element={<MainPage />}>
                        <Route path="published" element={<MainPage />} />
                    </Route>
                    <Route path="/test" element={<TestCreation />}>
                        <Route path=":testId" element={<TestCreation />}>
                            <Route path=":questions" element={<TestCreation />} />
                            <Route path=":scales" element={<TestCreation />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                limit={3}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            <Tooltip id="react-tooltip" className="react-tooltip" />
        </>
    );
}

export default App;
