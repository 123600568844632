import React from "react";
import Header from "../../components/Header/Header";
import bannerImg from "./img/bannerImg.svg"
import "./GeneralPage.css"
import "../../App.css"
import {Link} from "react-router-dom";
import {createTest} from "../../Redux/reducers/mainReducer";
import {useDispatch, useSelector} from 'react-redux'
import {Footer} from "../../components/Footer/Footer";
import { TESTU_LINK } from '../../config'
import {Alert} from '../../components/Alert/Alert'

export function GeneralPage(){
    const { user } = useSelector((state) => state.user);
    const { alert } = useSelector(state => state.alert)
    let dispatch = useDispatch();

    return (
       <div className="generalPage">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <Header/>
            <div className="content">
                <div className="container minHeight">
                    <div className="banner">
                        <h1>Конструктор тестов.<br/>Создай новый тест или опрос.</h1>
                        <div className="row">
                            {user?._id ?
                                <Link to="/test"><button onClick={() => {dispatch(createTest())}} className="purpleFilled">Создать тест</button></Link>
                                :
                                <a href={`${TESTU_LINK}/psych/login`}><button className="purpleFilled">Создать тест</button></a>
                            }
                        </div>
                        <img className="bannerImg" src={bannerImg} alt="Баннер"/>
                    </div>
                </div>
                {/*<GeneralRecommendations/>*/}
            </div>
           <Footer/>
       </div>
   )
}